<template>
  <div class="problemfeedback">
    <div class="bg">
      <div class="content">
        <div class="title">
          <span>问题反馈</span>
        </div>

        <div class="problem-type">
          <span>反馈的问题类型</span>
          <el-select v-model="problem_type" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="upload_img-module">
          <el-upload
            action="http://www.soutudashi.com/api/upload_feedback"
            :auto-upload="false"
            show-file-list
            list-type="picture-card"
            :limit="3"
            ref="upload"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
            :on-remove="handleRemove"
            :on-change="handleChange"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选择图片</el-button
            >
          </el-upload>
          <!-- <input
            type="file"
            class="file_input"
            multiple
            id="fileInput"
            @change="uploadImage"
          />
          <label class="upload_btn" for="fileInput">上传图片</label> -->
          <!-- <div class="img-list">
            <div class="img-item" v-for="(item, index) in uploaded_img_list">
              <img
                class="img-as"
                :src="base_url + item.filename"
                alt="Image Preview"
                :key="index"
                :class="getImgClassName(index)"
              />
              <div class="img-close-btn" @click="close_img($event, index)">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div> -->
        </div>

        <div class="problem-describe">
          <span class="problex-text">问题描述:</span>
          <el-input
            type="textarea"
            placeholder="请输入您的意见或建议"
            v-model="desc"
            rows="10"
            maxlength="300"
            show-word-limit
            style="margin-top: 20px"
            class="problem-area"
          >
          </el-input>
        </div>

        <button class="submit_btn" @click="submit_problem">提交</button>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
var AliOss = require("ali-oss");
import { get_token,clear_cookie,clearLoginInfo } from "../common/common.js";
export default {
  data() {
    return {
      un_upload_img_list: [],
      uploaded_img_list: [],
      problem_type: "",
      desc: "",
      fileList: [],
      options: [
        {
          value: "1",
          label: "页面BUG",
        },
        {
          value: "2",
          label: "意见和建议",
        },
        {
          value: "3",
          label: "其他",
        },
      ],
    };
  },
  created() {},
  methods: {
    handleRemove() {
      // console.log("handleremovie: ", this.fileList);
    },
    handleSuccess() {
      // console.log("handleSuccess: ", this.fileList);
    },
    // beforeUpload() {
    //   console.log("beforeUpload: ", this.fileList);
    // },
    beforeUpload(file) {
      this.fileList.push(file.name);
      const fileName = file.name;
      // console.log("上传文件的名字：", fileName, file);
      return true; // 返回true表示允许上传，返回false表示阻止上传
    },

    handleChange(file, fileList) {
      let imgSize = Number(file.size / 1024 / 1024);
      if (imgSize > 3) {
        this.$msgbox({
          title: "",
          message: "文件大小不能超过3MB，请重新上传。",
          type: "warning",
        });
        fileList.pop();
      }
    },
    message_box(type, message) {
      this.$message({
        showClose: true,
        message: message,
        type: type,
        customClass: "messageIndex",
      });
    },
    // 添加图片 但未上传
    uploadImage(event) {
      const file_list = event.target.files;
      //   console.log("filepath: ", filePath, " dddd ", fileInput); // 在控制台输出图片路径
      for (let index = 0; index < file_list.length; index++) {
        const file = file_list[index];

        // 判断上传的文件是否为图片，不过就给个告警，并跳过当前图片
        if (!this.isImage(file)) {
          this.message_box("error", "上传的文件应是图片");
          continue;
        } else if (!this.fileSizeLegal(file)) {
          this.message_box("error", "上传的文件大小应小于等于1M");
          continue;
        }
        const fileName = Date.now() + "-" + file.name;
        this.ossClient
          .put(fileName, file)
          .then((res) => {
            // console.log("上传成功", res);
            this.uploaded_img_list.push({ filename: fileName, file: file });
          })
          .catch((err) => {
            console.error("上传失败", err);
          });
      }

      event.target.value = "";
    },

    getImgClassName(index) {
      if (index <= 5) {
        return "first-line";
      } else {
        return "last-line";
      }
    },

    // 判断上传的文件是否为图片
    isImage(file) {
      const imageExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "tiff",
        "tif",
        "webp",
        "svg",
      ];

      const fileExtension = file.name.split(".").pop().toLowerCase();
      return imageExtensions.includes(fileExtension);
    },

    // 判断上传的文件大小是否合法
    fileSizeLegal(file) {
      // console.log("当前文件的大小: ", file.size);
      return file.size / 1024 <= 1024 * 5 ? true : false;
    },

    // 点击提交问题反馈信息
    async submit_problem() {
      // console.log("点击提交哦");
      // console.log("图片链接：", this.uploaded_img_list);
      // console.log("选择的问题类型: ", this.problem_type);
      // console.log("填写的问题描述: ", this.desc);

      console.log("fileListfileListfileList: ", this.fileList);
      const user_obj = localStorage.getItem("userInfo");
      const user_name = JSON.parse(user_obj)["user_name"];

      var pic_list = [];
      for (let i = 0; i < this.uploaded_img_list.length; i++) {
        pic_list.push(this.uploaded_img_list[i]["filename"]);
      }
      const data = {
        userName: user_name,
        problemType: this.problem_type,
        desc: this.desc,
        fileList: this.fileList,
      };

      if (this.problem_type == "") {
        this.message_box("error", "问题类型为必填");
        return;
      } else if (this.desc.trim().length == 0) {
        this.message_box("error", "问题描述为必填");
        return;
      } else if (this.desc.trim().length < 10) {
        this.message_box("error", "问题描述至少需要10个字");
        return;
      }
      // console.log("data: ", data);
      try {
        await this.$refs.upload.submit();

        const token = get_token("token");

        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/submit_problem", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/submit_problem",
        //   {
        //     headers: { "Content-Type": "application/json" },
        //     data,
        //   }
        // );

console.log("提交问题： ",response)
        // if (response.data.status == 405) {
          // this.message_box("error", "登陆过期了，请重新登陆");
          // clear_cookie('token')
          // clearLoginInfo()
          // // this.$store.commit("SET_LOGIN", false);
          // window.sessionStorage.removeItem("vuex")
          // // window.onlo
          // this.$router.go(0);
          // return ;
        // }else if(response.data.status != 200){
                    // this.message_box("error", "问题没有提交成功");
                    // return;
        // } else {
          // console.log("接口返回结果response: ");
          // console.log(response.data[0]);
          // this.$router.replace({ path: "/" });
          // this.close_login_box(true);
          this.message_box("success", "问题提交成功");

          this.$router.push("/submit_success");

          // 3秒后跳转到上一个路径
          setTimeout(() => {
            this.$router.go(-1);
          }, 3000); // 3000毫秒后执行跳转
          return;
        
        //   console.log(response);
      } catch (e) {
        console.log(e);
        if(e.response.status === 405){
                this.message_box("error", e.response.data.message);
                return
        }else{
           this.message_box("error", "问题没有提交成功");
           return
        }

      }
    },

    //点击关闭图片
    close_img(e, index) {
      // console.log("删除图片： ", e, e.target.parentNode.parentNode, index);
      if (index > -1 && index < this.uploaded_img_list.length) {
        // console.log("删除前: ", this.uploaded_img_list);
        this.uploaded_img_list.splice(index, 1);
        // console.log("删除后: ", this.uploaded_img_list);
      }
    },
  },
};
</script>

<style scoped>
.problemfeedback {
  width: 100%;
  height: 100%;
}

.problemfeedback .bg {
  width: 1300px;
  height: auto;
  margin: 20px auto;
  background-color: white;
}

.bg .content {
  width: 1120px;
  margin: 10px auto;
}

.submit-success span {
}
.title span {
  font-size: 30px;
}

.problem-type {
  margin-top: 20px;
  display: flex;
  /* width: auto; */
  justify-content: flex-start;
  align-items: center;
}
.problem-type span {
  line-height: 40px;
  font-size: 22px;
}

.problem-type .el-select {
  width: 200px;
  margin-left: 20px;
  /* float: left; */
  /* flex: 1; */
}

.upload_img-module {
  margin: 20px auto 20px auto;
  width: 1120px;
  text-align: left;
}

.img-list {
  margin-top: 20px;
  width: auto;
  min-height: 180px;
  border: 1px dashed black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

input[type="file"] {
  display: none;
}

input[type="file"] + label {
  background-color: green;
  padding: 10px 20px;
  width: 110px;
  height: 45px;
  border: 1px solid #ccc;
  cursor: pointer;
  box-sizing: border-box;
}

.upload_btn {
  display: block;
  border-radius: 20px;
  color: white;
}

.upload_btn:hover {
  scale: 1.1;
}

.img-list img {
  width: 200px;
  height: 150px;
}

.img-list img.first-line {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.img-list img.last-line {
  margin-left: 20px;
  /* margin-top: 20px; */
  margin-bottom: 20px;
}

.img-list .img-item {
  position: relative;
}
.problem-describe {
  /* display: flex; */
  width: 100%;
}

.problem-describe span {
  float: left;
  display: block;
}

.problem-area {
  width: 100%;
  margin-top: 20px;
  border: 1px dashed black;
}
.submit_btn {
  margin: 20px auto;
  background-color: green;
  /* padding: 10px 20px; */
  width: 100px;
  height: 45px;
  border: 1px solid #ccc;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
}

.submit_btn:hover {
  scale: 1.1;
}

.img-close-btn {
  position: absolute;
  right: 0px;
  top: 20px;
  width: 25px;
  height: 25px;
}

.img-close-btn i {
  width: 100%;
  height: 100%;
  font-size: 25px;
  line-height: 25px;
}

.img-close-btn i:hover {
  transform: rotate(180deg);
  transition: transform 0.5s;
}

.messageIndex {
  z-index: 999999 !important;
}
</style>
