<template>
  <div class="feedback">
    <div class="content">
      <ProblemFeedback></ProblemFeedback>
      <DividerLineWithTitle class="divide-line"></DividerLineWithTitle>
      <router-view class="main"></router-view>
    </div>
  </div>
</template>

<script type="text/babel">
import ProblemFeedback from "./ProblemFeedback.vue";
import DividerLineWithTitle from "../components/DividerLineWithTitle.vue";

export default {
  name: "FeedBack",
  components: { ProblemFeedback, DividerLineWithTitle },
};
</script>

<style scoped>
.content {
  width: 1300px;
  margin: 20px auto;
}
.divide-line {
  margin: 20px auto;
}

.feedback {
  width: 100%;
}
</style>
