<template>
  <div class="dashed-text">
    <!-- 你的元素内容 -->
    <div class="first-part"></div>
    <div class="sec-part">
      <span>{{ title }}</span>
    </div>
    <div class="third-part"></div>
  </div>
</template>

<script type="text/babel">
export default {
  data() {
    return {
      title: "以下是历史问题反馈",
    };
  },
};
</script>
<style>
.dashed-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.first-part,
.third-part {
  border: 1px dashed black;
  border-spacing: 100px;
  flex: 1;
  /* background-color: green; */

  /* width: 50%; */
  /* height: 50px; */
  /* width: 200px; */
  /* display: block; */
}

.sec-part {
  /* width: 25%; */
  flex: 0.3;
  /* width: 100px; */
  /* height: 50px; */
}
</style>
